import '../styles/base.scss'
import '../global/index.js'
import { useStaticQuery, graphql } from 'gatsby'
import linkResolver from '../../contentful/linkResolver'
import React, { createContext } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import logotype from '../../static/logo.svg'
import Menu from '../components/menu'
import appleTouchIcon from '../../static/apple-touch-icon.png'
import favicon16x16 from '../../static/favicon-16x16.png'
import favicon32x32 from '../../static/favicon-32x32.png'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import richTextRendererOptions from '../../contentful/richTextRendererOptions'
import Cookies from '../components/cookies'

const LayoutContext = createContext({
  siteName: '',
  siteUrl: '',
})

const Layout = ({ children }) => {
  const result = useStaticQuery(frameworkQuery)
  const start = result.allContentfulStartpage.edges[0].node

  const footerCol1 = start.footerCol1
    ? documentToReactComponents(start.footerCol1.json, richTextRendererOptions)
    : null
  const footerCol2 = start.footerCol2
    ? documentToReactComponents(start.footerCol2.json, richTextRendererOptions)
    : null
  const footerCol3 = start.footerCol3
    ? documentToReactComponents(start.footerCol3.json, richTextRendererOptions)
    : null
  const footerCol4 = start.footerCol4
    ? documentToReactComponents(start.footerCol4.json, richTextRendererOptions)
    : null

  const menuItems = start.childPages.map(node => {
    return {
      link: linkResolver(node),
      id: node.id,
      pageName: node.title,
    }
  })

  menuItems.unshift({
    link: '/',
    id: 'start',
    pageName: 'Filmer',
  })

  return (
    <LayoutContext.Provider
      value={{
        siteName: start.siteName,
        siteUrl: start.siteUrl,
      }}
    >
      <div>
        <Cookies />
        <a
          href="#innehall"
          className="sr-only focus:not-sr-only focus:bg-purple-300 text-white text-xl skip"
        >
          Gå till huvudinnehåll
        </a>
        <header className="flex justify-between py-10 px-6 md:px-5 border-b">
          <Link to="/" className="md:mx-4 block">
            <img
              src={logotype}
              alt="Koll på cashen (till startsidan)"
              width="150"
            />
          </Link>
          {/* <nav className="hidden md:flex justify-end -mt-2" id="menu">
          <ul className="flex">
            <li className="mx-4 text-xl">
              <Link to="/">Filmer</Link>
            </li>
            {start.childPages.map((node, i) => {
              return (
                <li className="mx-4 text-xl" key={i}>
                  <Link to={linkResolver(node)}>{node.title}</Link>
                </li>
              )
            })}
          </ul>
        </nav> */}

          <Menu items={menuItems} />
        </header>
        <div>
          <Helmet>
            <html lang="sv" />
            {/* <body className="bg-white" /> */}
            <link rel="stylesheet" href="//use.typekit.net/mxc0hgs.css"></link>
            <link
              rel="stylesheet"
              href="//unicons.iconscout.com/release/v3.0.0/css/line.css"
            ></link>
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href={appleTouchIcon}
            ></link>
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href={favicon32x32}
            ></link>
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href={favicon16x16}
            ></link>
          </Helmet>
          {children}
        </div>
        <footer className="bg-gray-100 px-6 md:px-0">
          <div className="max-w-screen-xl mx-auto py-12 md:py-32">
            <h2 className="text-2xl font-bold text-gray-900 mb-3 block">
              Kontakt
            </h2>
            <div className="flex flex-wrap text-lg text-gray-700 mb-12 md:mb-20">
              <div className="w-full mb-4 md:mb-0 md:w-1/5">{footerCol1}</div>
              <div className="w-full mb-4 md:mb-0 md:w-1/5 pr-4">
                {footerCol2}
              </div>
              <div className="w-full mb-4 md:mb-0 md:w-1/4 pr-4">
                {footerCol3}
              </div>
              <div className="w-full mb-4 md:mb-0 md:w-1/5 pr-4">
                {footerCol4}
              </div>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-700">©2020 Koll på cashen</span>
              <Link to="/" className="mx-4 block">
                <img
                  src={logotype}
                  alt="Koll på cashen (till startsidan)"
                  width="100"
                  className="grayscale"
                />
              </Link>
            </div>
          </div>
        </footer>
      </div>
    </LayoutContext.Provider>
  )
}
export { LayoutContext, Layout }

const frameworkQuery = graphql`
  query {
    allContentfulStartpage {
      edges {
        node {
          title
          siteName
          siteUrl
          childPages {
            ...ContentfulLink
          }
          footerCol1 {
            json
          }
          footerCol2 {
            json
          }
          footerCol3 {
            json
          }
          footerCol4 {
            json
          }
        }
      }
    }
  }
`
