
module.exports = {
  'sv-SE': {
    show_all: 'Gå till pressrummet',
    news: 'Nyheter och pressmeddelanden',
    newsSlug: 'nyheter-och-press',
    language: 'English',
    production: 'Nuvarande produktion på O3',
    production_readmore:  'Läs mer om vår produktion',
    news_tag: 'Nyhet',
    press_tag: 'Pressmeddelande',
    published: 'Publicerad',
    error: 'Något gick fel.',
    subscriber_exists: ' finns redan som prenumerant.',
    subscribe_success: 'Tack! Du prenumererar nu på OKGs nyhetsbrev och pressmeddelanden.',
    subscribe: 'Prenumerera på vårt nyhetsbrev',
    subscribe_text: 'Ange din e-post för att få våra pressmeddelanden och nyheter skickade till dig.',
    email: 'E-postadress',
    subscribe_button: 'Prenumerera',
    show_all_news: 'Visa alla nyheter',
    blog: 'Kärnpunkten',
    blog_slug: 'karnpunkten',
    blog_introduction: 'Kärnpunkten är OKGs egna personalmagasin på webben lorem ipsum dolor sit amet molestie turpis nibh vulputate viverra nulla nibh cursus.',
    download_original: 'Ladda ner original',
    close: 'Stäng',
    cookie: 'Vi använder cookies för att webbplatsen ska fungera på ett bra sätt för dig. Genom att surfa vidare godkänner du att vi använder cookies.',
    cookie_button: 'Jag förstår',
    logo_alt: 'Oskarshamns kärnkraftverk - OKG (till startsidan)',
    show_blog:'Gå till Kärnpunkten'
  },
  'en-US' : {
    show_all: 'Show all',
    news: 'News and Press Releases',
    newsSlug: 'press-room',
    language: 'Svenska',
    production: 'Current production on O3',
    production_readmore:  'More about our production',
    news_tag: 'News',
    press_tag: 'Press Release',
    published: 'Published',
    error: 'Something went wrong.',
    subscriber_exists: ' already exists.',
    subscribe_success: 'Thank you! You are now a subscriber of our news letter.',
    subscribe: 'Subscribe to our news letter',
    subscribe_text: 'Enter your email to recieve updates from us.',
    email: 'E-mail address',
    subscribe_button: 'Subscribe',
    show_all_news: 'Show all news',
    blog: 'Kärnpunkten',
    blog_slug: 'karnpunkten',
    download_original: 'Download original',
    close: 'Close',
    cookie: 'We use cookies to collect anonymous analytics data in order to provide a better experience for you. By continuing to use this site, you approve our usage of cookies.',
    cookie_button: 'I understand',
    logo_alt: 'Oskarshamn nuclear power plant - OKG (to the startpage)'
  }
}