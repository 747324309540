import React from 'react'
import './video.scss'

function Embed({ embed, title }) {

  let embedWithoutCookies = embed.replace('www.youtube.com', 'www.youtube-nocookie.com')

  return (
    <div className="video">
      <div dangerouslySetInnerHTML={{ __html: embedWithoutCookies }}></div>
    </div>
  )
}
export default Embed
