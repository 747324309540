import React from 'react'
import { Layout } from './src/layout'

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (!location.state) {
    return true
  }
  if (location.state.scroll === false) {
    return false
  }
  if (location.state.scrollTo) {
    return location.state.scrollTo
  }
  return true
}
