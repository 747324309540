import React, { useLayoutEffect, useState, useCallback } from 'react'
import './cookies.scss'
import Cookies from 'js-cookie'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router' // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

const COOKIE_NAME = 'gatsby-gdpr-google-analytics'

function CookieInfo() {
  const [hidingCookieWarning, setHidingCookieWarning] = useState(true)
  const [showCookieWarning, setShowCookieWarning] = useState(false)
  let location = useLocation()

  const hideCookieWarning = useCallback(() => {
    Cookies.set(COOKIE_NAME, true, {
      expires: 365,
      sameSite: 'None',
      secure: true,
    })
    initializeAndTrack(location)
    setHidingCookieWarning(true)
    setTimeout(() => {
      setShowCookieWarning(false)
    }, 1000)
  }, [])

  useLayoutEffect(() => {
    if (window && !Cookies.get(COOKIE_NAME)) {
      setShowCookieWarning(true)
      setTimeout(() => {
        setHidingCookieWarning(false)
      }, 1000)
    }
  }, [hideCookieWarning])

  if (showCookieWarning === false) return null

  return (
    <div
      className={
        hidingCookieWarning
          ? 'cookies cookies--hidden bg-gradient-to-r bg-purple-300 to-purple-500 from-purple-300'
          : 'cookies bg-gradient-to-r bg-purple-300 to-purple-500 from-purple-300'
      }
    >
      <div className="max-w-screen-xl mx-auto md:flex items-center py-10 px-6">
        <div className="md:w-2/3">
          <p className="text-xl">
            På kollpacashen.se används cookies för att analysera besöksstatistik
            och för att i sin tur förbättra webbplatsen. Du väljer själv om du
            accepterar cookies.{' '}
            <Link to="/cookies" className="underline">
              Läs mer om cookies.
            </Link>
          </p>
        </div>
        <div className="md:w-1/3 mt-4 md:mt-0 flex md:justify-end">
          <button
            className="py-6 px-10 block bg-white text-gray-900 text-lg font-bold rounded-full whitespace-no-wrap shadow-lg"
            onClick={() => {
              hideCookieWarning()
            }}
          >
            Jag är ok med det
          </button>
        </div>
      </div>
    </div>
  )
}

export default CookieInfo
