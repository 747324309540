import { Link } from 'gatsby'
import React, { useState, useEffect, useLayoutEffect, useContext } from 'react'
// import { MdLanguage } from 'react-icons/md'
import MobileNavButton from '../mobileNavButton'
// import { LayoutContext } from '../../layout/index.js'
import {
  disableFocusBehindModal,
  enableFocusBehindModal,
} from '../../util/modalHelper'
import './menu.scss'

function Menu({ items }) {
  const [menuExpanded, setMenuExpanded] = useState(false)
  const [menuCollapsing, setMenuCollapsing] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  // const { localization, locale } = useContext(LayoutContext)

  useEffect(() => {
    const maxWidth = 768
    matchMedia(`screen and (max-width:${maxWidth}px)`).addListener(function(
      mql
    ) {
      setIsMobile(mql.matches)
      if(mql.matches) {
        collapseMenu()
      }
    })
  }, [])

  useLayoutEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth <= 768) {
      setIsMobile(true)
    }
  }, [])

  function handleEsc(e) {
    if (e.keyCode === 27) {
      collapseMenu()
    }
  }

  function toggleMenu(e) {
    if (menuExpanded) {
      collapseMenu()
    } else {
      setMenuExpanded(true)
      if (document) {
        disableFocusBehindModal(document.getElementById('Menu'))
        document.addEventListener('keydown', handleEsc)
      }
    }
  }

  function collapseMenu() {
    setMenuCollapsing(true)
    setMenuExpanded(false)
    setTimeout(() => {
      setMenuCollapsing(false)
    }, 200)
    if (document) {
      document.removeEventListener('keydown', handleEsc)
      enableFocusBehindModal()
    }
  }

  const menuItems = items.map(item => {
    return (
      <MenuItem
        href={item.link}
        key={item.id}
        disabled={!menuExpanded && isMobile}
        className="block text-2xl font-bold md:font-normal my-4 md:my-0 md:mx-4 md:text-xl"
        closeMenu={toggleMenu}
        isMobile={isMobile}
      >
        {item.pageName}
      </MenuItem>
    )
  })

  // const additionalMenuItems = additionalItems.map(item => {
  //   return (
  //     <MenuItem
  //       href={item.link}
  //       key={item.id}
  //       disabled={!menuExpanded && isMobile}
  //       className="menu__additional__item"
  //       closeMenu={toggleMenu}
  //       isMobile={isMobile}
  //     >
  //       {item.pageName}
  //     </MenuItem>
  //   )
  // })

  let opts = {}
  if (menuExpanded) {
    opts['className'] =
      'inset-0 md:inset-auto fixed md:static bg-gray-100 md:bg-transparent z-40 flex md:flex md:-mt-2 md:justify-end'
    // if (typeof document !== 'undefined')
    //   document.body.classList.add('menu-expanded')
  } else {
    // opts['className'] = menuCollapsing ? 'menu menu--collapsing' : 'menu'
    opts['className'] =
      'inset-0 md:inset-auto fixed md:static bg-gray-100 md:bg-transparent z-40 hidden md:flex md:-mt-2 md:justify-end'
    // opts['aria-hidden'] = isMobile
    // if (typeof document !== 'undefined')
    //   document.body.classList.remove('menu-expanded')
  }

  return (
    <React.Fragment>
      <MobileNavButton
        expanded={menuExpanded}
        handleToggleMenu={toggleMenu}
        ariaControls="Menu"
      />

      {/* <nav className="hidden md:flex justify-end -mt-2" id="menu">
        <ul className="flex">
          <li className="mx-4 text-xl">
            <Link to="/">Filmer</Link>
          </li>
          {start.childPages.map((node, i) => {
            return (
              <li className="mx-4 text-xl" key={i}>
                <Link to={linkResolver(node)}>{node.title}</Link>
              </li>
            )
          })}
        </ul>
      </nav> */}

      <nav {...opts} id="Menu" aria-label="Huvudmeny">
        <ul className="flex flex-col md:flex-row w-full md:w-auto justify-center items-center md:items-start md-justify-start bg-gray-100 md:bg-transparent">
          {menuItems}
        </ul>
        {/* <ul className="flex flex-col w-full justify-center items-center bg-gray-100">
          <li>
            <a href="/" className="block text-2xl font-bold my-4">
              Filmer
            </a>
          </li>
          <li>
            <a href="/" className="block text-2xl font-bold my-4">
              För lärare
            </a>
          </li>
          <li>
            <a href="/" className="block text-2xl font-bold my-4">
              För elever
            </a>
          </li>
        </ul> */}
      </nav>
    </React.Fragment>
  )
}

function MenuItem({
  href,
  children,
  disabled,
  className,
  closeMenu,
  isMobile,
}) {
  let opts = {
    className:'block rounded hover'
  }
  if (disabled) {
    opts['tabIndex'] = '-1'
  }
  const isActive = ({ isCurrent }) => {
    return isCurrent ? { 
      className: 'block border-b-4 border-purple-300' 
      
    } : null
  }
  return (
    <li className={className}>
      <Link
        {...opts}
        to={href}
        onClick={() => {
          if (isMobile) closeMenu()
        }}
        getProps={isActive}
      >
        {children}
      </Link>
    </li>
  )
}

export default Menu
