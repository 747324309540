import React from 'react'
import './mobileNavButton.scss'

function MobileNavButton({ expanded, handleToggleMenu, ariaControls }) {
  return (
    <button
      aria-expanded={expanded}
      aria-controls={ariaControls}
      aria-label="Huvudmeny"
      onClick={handleToggleMenu}
      className="z-50 text-xl color-gray-900 flex md:hidden flex-col items-center -mt-2 rounded"
    >
      {!expanded && (
        <i className="uil uil-bars text-4xl -mb-3" aria-hidden="true"></i>
      )}
      {expanded && (
        <i className="uil uil-times text-4xl -mb-3" aria-hidden="true"></i>
      )}
      Meny
    </button>
  )
}

export default MobileNavButton
