import React from 'react'
import './textBody.scss'

function TextBody({ children, body, useSerif }) {
  return (
    <div className={`textbody ${useSerif ? 'textbody--serif' : ''}`}>
      {children}
      <div dangerouslySetInnerHTML={{ __html: body }}></div>    
    </div>
  )
}
export default TextBody
