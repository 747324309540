import React from 'react'
import { INLINES, BLOCKS } from '@contentful/rich-text-types'
import linkResolver from './linkResolver'
import TextContainer from '../src/components/textContainer/textContainer'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Accordion from '../src/components/accordion'
import Embed from '../src/components/embed'
import Documents from '../src/components/documents/documents'
import { getFluidGatsbyImage } from './getFluidGatsbyImage'
import Image from '../src/components/image/image'
import ButtonLink from '../src/components/buttons/buttonLink'
import Contacts from '../src/components/contacts/contacts'

export default {
  renderText: text =>
    text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  renderNode: {
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const href = linkResolver({
        slug: node.data.target.fields.slug,
        pageType: node.data.target.fields.pageType
          ? node.data.target.fields.pageType
          : null,
      })

      return <a href={href}>{children}</a>
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      return <a href={node.data.target.fields.file.url}>{children}</a>
    },
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const contentType = node.data.target.sys.contentType.sys.id
      const fields = node.data.target.fields
      switch (contentType) {
        case 'contentBlock':
          const { title, body, hideTitle } = node.data.target.fields
          return (
            <TextContainer
              title={title}
              body={documentToReactComponents(body)}
              hideTitle={hideTitle}
            />
          )

        case 'accordionBlock':
          const { contentBlocks } = node.data.target.fields
          const accordionItems = contentBlocks.map(({ fields }) => {
            return {
              title: fields.title,
              body: documentToReactComponents(fields.body),
            }
          })
          return <Accordion items={accordionItems} />

        case 'embedBlock':
          return <Embed title={fields.title} embed={fields.embedCode} />

        case 'relatedLinksBlock':
          const relatedLinkItems = fields.relatedAssets.map(asset => {
            return {
              title: asset.fields.title,
              url: asset.fields.file.url,
            }
          })
          return <Documents items={relatedLinkItems} title={fields.title} />

        case 'imageBlock':
          return (
            <Image
              image={getFluidGatsbyImage(fields.image.fields, { width: 760 })}
              imageText={fields.imageText}
              altText={fields.altText}
            />
          )

        case 'callToActionBlock':
          if (!fields.externalUrl && !fields.pageReference) return null
          const href = fields.externalUrl
            ? fields.externalUrl
            : linkResolver(fields.pageReference.fields)
          return (
            <ButtonLink
              openInNewWindow={fields.openInNewWindow}
              href={href}
              scroll={
                fields.pageReference &&
                fields.pageReference.pageType === 'Overlay page'
              }
            >
              {fields.title}
            </ButtonLink>
          )

        case 'contactBlock':
          const contactItems = fields.contacts.map(contactItem => {
            return {
              name: contactItem.fields.name,
              title: contactItem.fields.title,
              phone: contactItem.fields.phone,
              email: contactItem.fields.email,
              image: getFluidGatsbyImage(contactItem.fields.image.fields, {
                width: 248,
                height: 248,
              }),
            }
          })
          return (
            <Contacts
              contacts={contactItems}
              title={fields.title}
              showTitle={fields.showTitle}
            />
          )
      }
    },
  },
}
