import React from 'react'
import Img from 'gatsby-image'
import './image.scss'

function Image({ image, imageText, altText }) {
  return (
    <div>
      {image && (
        <div className="content-image">
          <Img
            fluid={image}
            placeholderClassName="lazy"
            alt={altText}
          />
          {imageText && <p>{imageText}</p>}
        </div>
      )}
    </div>
  )
}

export default Image
